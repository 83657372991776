.rhap_progress-section {
  gap: 13px;
}

.rhap_container {
  background: transparent;
  padding: 0;
  box-shadow: none;
}

.rhap_progress-container {
  margin: 0 7px;

  .rhap_progress-bar {
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    background: #bfc9f6;

    .rhap_progress-indicator {
      display: none;
    }

    .rhap_progress-filled {
      height: 8px;
      border-radius: 8px;
      background: linear-gradient(103.32deg, #3e72fd 52.9%, #6a8bff 98.38%);
    }

    .rhap_download-progress {
      display: none;
    }
  }
}

.rhap_time {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  font-feature-settings: 'tnum';

  &.rhap_current-time {
    color: #ffffff;
  }

  &.rhap_total-time {
    color: #cdcdcd;
  }
}

.rhap_volume-bar {
  height: 8px;
  border-radius: 8px;
  overflow: hidden;
  background: #f3f4f6;

  .rhap_volume-indicator {
    display: none;
  }

  .rhap_volume-filled {
    height: 8px;
    border-radius: 8px;
    background: linear-gradient(103.32deg, #3e72fd 52.9%, #6a8bff 98.38%);
  }
}

.rhap_volume-button {
  margin: 0;
  min-width: 40px;
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
}

.rhap_controls-section {
  display: none;
}

.rhap_play-pause-button {
  display: flex;
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: 0;
  overflow: visible;
  outline: none;
  opacity: 1 !important;
  transition-duration: 0 !important;
}

.rhap_container.waves {
  .rhap_progress-section {
    gap: 13px;
  }

  .rhap_container {
    width: fit-content;
    background: transparent;
    padding: 13px 0 13px 16px;
    box-shadow: none;
  }

  .rhap_progress-container {
    margin: 0;
    width: 128px;
    height: 24px;

    -webkit-mask-image: url('~assets/icons/canvas/soundtrack-pattern.png');
    mask-image: url('~assets/icons/canvas/soundtrack-pattern.png');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: auto 100%;
    mask-size: 100%;

    .rhap_progress-bar {
      width: 128px;
      height: 24px;
      overflow: hidden;
      background: #4b4f76;
      border-radius: 0;

      .rhap_progress-indicator {
        display: none;
      }

      .rhap_progress-filled {
        height: 100%;
        border-radius: 0;
        background: #3e72fd;
      }

      .rhap_download-progress {
        display: none;
      }
    }

    @media (max-width: 576px) {
      width: 88px;

      -webkit-mask-image: url('~assets/icons/canvas/soundtrack-pattern-mobile.png');
      mask-image: url('~assets/icons/canvas/soundtrack-pattern-mobile.png');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: auto 100%;
      mask-size: 100%;
    }
  }

  .rhap_controls-section {
    display: none;
  }

  .rhap_play-pause-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 0;
    overflow: visible;
    outline: none;
    opacity: 1 !important;
    transition-duration: 0 !important;
    background: #3e72fd;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}
