@import './variables';

* {
  font-family: 'Quicksand', sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  @include mobile {
    * {
      touch-action: none;
    }
  }
}

body {
  min-width: 320px;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

svg {
  outline: none;
}

audio::-webkit-media-controls-panel {
  background-color: #f8ecd0;
}

.react-tooltip {
  pointer-events: auto !important;
}

.CircularProgressbar {
  overflow: visible;
}

.CircularProgressbar.blue .CircularProgressbar-path {
  filter: drop-shadow(0px 0 3px #3f6aff) drop-shadow(0px 0 5px #3f6aff);
}

.CircularProgressbar.yellow .CircularProgressbar-path {
  filter: drop-shadow(0px 0 3px #d2b725) drop-shadow(0px 0 5px #d2b725);
}

.CircularProgressbar.pink .CircularProgressbar-path {
  filter: drop-shadow(0px 0 3px #f244cc) drop-shadow(0px 0 5px #f244cc);
}

.CircularProgressbar.green .CircularProgressbar-path {
  filter: drop-shadow(0px 0 3px #2fcf49) drop-shadow(0px 0 5px #2fcf49);
}

@import 'react-h5-audio-player/src/styles.scss';

@import './AudioPlayer.module.scss';

#pageLoader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $darkThemeLight;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 150;
  padding-bottom: 40px;

  & > div {
    width: 180px;
    position: relative;
    margin-top: 24px;

    & > div {
      height: 4px;
      background: linear-gradient(103.32deg, #3d4ff7 6.46%, #c145ed 98.38%);
      border-radius: 1000px;
      animation: loading 1s infinite ease-in-out;
      position: absolute;
    }
  }
}

@keyframes loading {
  0% {
    width: 0;
    left: 0;
  }
  35% {
    width: 180px;
    left: 0;
  }
  70% {
    left: 180px;
    width: 0;
  }
  71% {
    width: 0;
  }
}

.ns-resize * {
  cursor: ns-resize !important;
}
