@import '~/styles/variables';

.container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $darkThemeLight;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 150;
  padding: 48px 24px 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.title {
  font-size: 36px;
  font-weight: 500;
  color: $darkThemeText;
}
.subtitle {
  font-size: 18px;
  font-weight: 500;
  color: $darkThemeText;

  //   margin: 12px 0 24px;
}
.btn {
  min-width: 240px;
  height: 48px;
  padding: 0 24px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include gradientBgTransitionWithBorder(#3d4ff6, #c145ed, $darkThemeLight, 32%, 1px);
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  color: $darkThemeText;
}

.footer {
  font-size: 14px;
  font-weight: 500;
  color: $darkThemeDark2Text;
}
